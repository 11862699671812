.table {
  th {
    vertical-align: top;

    &.col-wide-lg {
      min-width: 250px !important;
    }

    &.col-wide-md {
      min-width: 200px !important;
    }

    &.col-wide-sm {
      min-width: 150px !important;
    }

    &.mw-250 {
      max-width: 250px !important;
    }
  }

  &.table-striped {
    >tbody>tr:nth-of-type(odd)>* {
      background-color: lighten($gray-100, 7%);
    }

    //--cui-table-striped-bg: $gray-200;
  }

  tbody {
    tr {
      td {
        position: relative;
        background-color: #fff;

        .label {
          padding: 0.2rem 0.35rem .15rem;
          font-size: .65rem;
          top: -1px;
        }
      }

      td.with-thumbnail {
        figure {
          width: 60px;
          height: 60px;
          overflow: hidden;
          position: relative;
          @include border-radius(3px);
        }

        .document-preview {

          .icon:not(.icon-c-s):not(.icon-custom-size),
          .icon {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
  }

  thead th.sticky,
  tbody td.sticky {
    position: sticky;
    left: 0;
    padding-right: .75rem;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, .04);
      z-index: -1;
    }
  }
}

.table-list {
  .no-results {
    .icon-img {
      width: 4rem;
      height: 4rem;

      .icon {
        width: 100%;
        height: 100%;
      }
    }
  }
}