.draggable-list {
  .draggable-item {
    padding: .25rem 0;
  }
}

.draggable-placeholder {
  padding: .25rem 0;

  @media (min-width: map-get($grid-breakpoints, xxl)) {
    max-width: 90%;
  }

  span {
    height: 70px;
    display: block;
    background-color: $primary-pale;
    border: 4px dashed darken($primary-pale, 10%);
    @include border-radius(6px);
  }

  &.person {
    max-width: 500px;
  }
}

.cdk-drag {
  list-style: none;

  @media (min-width: map-get($grid-breakpoints, xxl)) {
    max-width: 90%;
  }

  .card {
    position: relative;
    border: 0;
    @include box-shadow(0, 0, 0, 1px, $gray-200, inset);
  }

  .card-body {
    @include align-items(center);
    padding-top: 1.6rem;
    padding-bottom: 1.5rem;
  }

  .content-wrapper {
    @include flex(1);
    padding-left: .75rem;
    padding-right: 1rem;

    @media (min-width: map-get($grid-breakpoints, md)) {
      @include flexbox();
      @include flex-wrap(wrap);
      @include align-items(center);
    }
  }

  .btn-draggable-handler.btn-square {
    color: $gray-400;
    width: 30px;
    height: 30px;
    border-color: transparent;

    &:hover {
      background-color: $gray-100;
      color: $gray-500;
      border-color: transparent;
    }
  }

  .btn-circle.btn-remove {
    width: 20px;
    height: 20px;
  }

  figure.image-preview {
    width: 180px;
    aspect-ratio: 16 / 9;
    margin-bottom: 1rem;
    overflow: hidden;
    @include border-radius(3px);

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-bottom: 0;
      width: 140px;
    }

    &.logo {
      aspect-ratio: 1 / 1;
      width: 100px;

      img {
        background-color: transparent;
      }
    }
  }

  .name {
    font-size: .9rem;
  }

  .info {
    @media (min-width: map-get($grid-breakpoints, md)) {
      padding-left: 1rem;
      @include flex(1);
    }
  }

  .status-label {
    @include inline-flex();
    position: absolute;
    top: 0;
    right: 0;

    .label {
      font-size: .65rem;
      padding: .25rem .35rem;
      margin: 0;
      @include border-radius(0 4px 0 4px);
    }
  }

  &.person {
    max-width: 500px;

    .content-wrapper {
      @include flexbox();
      @include flex-wrap(wrap);
      @include align-items(center);
    }

    figure.image-preview {
      width: 70px;
      margin-bottom: 0;
      aspect-ratio: 1 / 1;
    }

    .name {
      font-size: .9rem;
    }

    .info {
      @include flex(1);
      padding-left: 1.25rem;
    }
  }

  &.document {

    .content-wrapper {
      @include flexbox();
      @include flex-wrap(wrap);
      @include align-items(center);
    }

    .name {
      font-size: .9rem;
    }

    .info {
      @include flex(0 0 100%);
      margin-bottom: .5rem;

      @media (min-width: map-get($grid-breakpoints, md)) {
        margin-bottom: 0;
        @include flex(1);
      }
    }

    .display-name {
      @include flex(0 0 100%);

      @media (min-width: map-get($grid-breakpoints, md)) {
        @include flex(2);
        padding-left: 1.25rem;
      }
    }
  }

  &.cdk-drag-preview {
    z-index: 1060 !important;
  }
}