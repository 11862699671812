a.btn,
button.btn {
  padding: .375rem 1.25rem;
  min-width: 120px;
  @include inline-flex();
  @include align-items(center);
  @include justify-content(center);

  &.btn-primary {
    &:hover {
      color: #fff;
    }
  }

  &.btn-primary-darken {
    background-color: darken($primary, 10%);
    border-color: darken($primary, 10%);
    color: #fff;

    &:hover {
      color: rgba(255, 255, 255, .75);
      background-color: darken($primary, 15%);
      border-color: darken($primary, 15%);
    }
  }

  &.btn-inverted-primary {
    background-color: #fff;
    color: $primary;
    border-color: #fff;

    &:hover {
      color: $primary;
      background-color: rgba(255, 255, 255, .75);
      border-color: rgba(255, 255, 255, .1);
    }
  }

  &.btn-secondary {
    background-color: #f2f0eb;
    color: #000;

    &:hover {
      background-color: darken(#f2f0eb, 15%);
    }
  }

  &.btn-inverted-secondary {
    background-color: rgba(255, 255, 255, .25);
    color: #fff;
    border-color: rgba(255, 255, 255, .1);

    &:hover {
      background-color: rgba(255, 255, 255, .6);
      color: #fff;
      border-color: rgba(255, 255, 255, .05);
    }
  }

  &.btn-inverted-outline {
    background-color: transparent;
    color: #fff;
    border-color: #fff;

    &:hover {
      background-color: rgba(255, 255, 255, .6);
      color: #fff;
      border-color: rgba(255, 255, 255, .05);
    }
  }

  &.btn-danger {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  &.btn-outline-primary {
    &:hover {
      color: #fff;
    }
  }

  &.btn-ghost-primary {
    &:hover {
      color: #fff;
    }
  }

  &.btn-ghost-danger {
    &:hover {
      color: #fff;
    }
  }

  &.btn-outline-danger {
    &:hover {
      color: #fff;
    }
  }

  &.btn-link {
    padding: 0 !important;
    text-decoration: none;
    @include align-items(flex-start);
    text-align: left;

    &:hover {
      background-color: transparent;
      color: $primary;
      text-decoration: underline;
    }
  }

  &.btn-info {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  &.btn-tooltip {
    padding: 0 !important;
    color: white;
    background-color: $info;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: .85rem !important;
    font-weight: 600;
    font-style: italic;
    border: 0;

    &:hover {
      background-color: darken($info, 12%);
    }
  }

  .icon-img {
    position: relative;
    //top: -1px;
    width: 1.25rem;
    height: 1.25rem;

    svg,
    .icon,
    .icon:not(.icon-c-s):not(.icon-custom-size) {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &.btn-square {
    width: 44px;
    height: 44px;
    padding: 0 !important;
    min-width: 0 !important;

    &.btn-sm {
      width: 30px;
      height: 30px;
    }
  }

  &.btn-circle {
    width: 44px;
    height: 44px;
    padding: 0 !important;
    min-width: 0 !important;
    @include border-radius(50%);

    &.btn-sm {
      width: 30px;
      height: 30px;
    }
  }

  &.btn-xs {
    min-width: 0 !important;
    padding: .1rem .5rem;
    font-size: .65rem;

    &.btn-circle {
      width: 22px;
      height: 22px;
    }
  }

  &.btn-sm {
    min-width: 0 !important;
    padding: .25rem .75rem;
    min-height: 30px;

    .icon-img {
      width: .85rem;
      height: .85rem;
    }
  }
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  background-color: $gray-400 !important;
  border-color: $gray-500 !important;
}

.btn-close:focus {
  box-shadow: none;
}