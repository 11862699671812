/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "fonts";
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import styles with default layout.
@import "@coreui/coreui/scss/coreui";

// Some temp fixes
@import "fixes";

// Prism.js
@import "examples";

// Summernote 
@import '../../node_modules/summernote/dist/summernote-lite.min.css';

// CoreUI Icons
@import '@coreui/icons-pro/css/all.min.css';

// If you want to add something do it here
@import 'ngx-toastr/toastr';
@import 'ngx-toastr/toastr-bs5-alert';
@import "mixins";
@import "layout";
@import "global";
@import "toast";
@import "header";
@import "sidebar";
@import "label";
@import "forms";
@import "icon";
@import "card";
@import "buttons";
@import "modal";
@import "table";
@import "draggable";
@import "content-list-template";
@import "manage-content-template";
@import "datetimepicker";
@import "dropdown";
@import "pagination";
@import "charts";

/* Importing Bootstrap SCSS file. */
//@import 'bootstrap/scss/bootstrap';