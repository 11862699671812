.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  @include ltr-rtl("padding-right", var(--cui-sidebar-occupy-end, 0));
  will-change: auto;
  @include transition(padding .15s);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --cui-gutter-x: 2rem !important;
}

.body {
  padding-top: 3rem;
  padding-bottom: 3rem;

  .page-header {
    margin-bottom: 2.5rem;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid $gray-100;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      padding-bottom: 1rem;
    }
  }
}

html:not([dir=rtl]) .wrapper {
  --cui-sidebar-occupy-start: 14rem;

  @media (min-width: map-get($grid-breakpoints, xxl)) {
    --cui-sidebar-occupy-start: 16rem;
  }

}