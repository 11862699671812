$color_1: rgba(0, 0, 0, 0.87);
$color_2: #000000;
$color_3: rgba(0, 0, 0, 0.4);
$color_4: rgba(0, 0, 0, 0.85);
$color_5: inherit;
$color_6: rgba(255, 255, 255, 0.85);
$color_7: $primary;
$color_8: #ffffff;
$background-color_1: rgba(0, 0, 0, 0.12);
$background-color_2: $primary;
$background-color_3: rgba(0, 0, 0, 0.04);
$background-color_4: currentColor;
$background-color_5: transparent;
$background-color_6: rgba(0, 0, 0, 0.1);
$border-color_1: rgba(0, 0, 0, 0.4);
$border-color_2: rgba(0, 0, 0, 0.2);

.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1030;

  &:empty {
    display: none;
  }
}

.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: -webkit-box;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.288);
}

.cdk-overlay-transparent-backdrop {
  opacity: 0;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.owl-dialog-container {
  position: relative;
  pointer-events: auto;
  box-sizing: border-box;
  display: block;
  padding: 1.5em;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  overflow: auto;
  background: #fff;
  color: $color_1;
  width: 100%;
  height: 100%;
  outline: none;
}

.owl-dt-container {
  box-sizing: border-box;
  display: block;
  font-size: 1rem;
  background: #ffffff;
  pointer-events: auto;
  z-index: 1000;

  * {
    box-sizing: border-box;
  }
}

.owl-dt-container-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &:last-child {
    border-bottom: none;
  }
}

.owl-dt-calendar {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
}

.owl-dt-calendar-control {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 1em;
  width: 100%;
  padding: .5em;
  color: $color_2;

  .owl-dt-calendar-control-content {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;

    .owl-dt-calendar-control-button {
      padding: 0 .8em;

      &:hover {
        background-color: $background-color_1;
      }
    }
  }
}

.owl-dt-calendar-main {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 0 .5em .5em;
  outline: 0;
}

.owl-dt-calendar-view {
  display: block;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.owl-dt-calendar-multi-year-view {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;

  .owl-dt-calendar-table {
    width: calc(100% - 3em);

    .owl-dt-calendar-header {
      th {
        padding-bottom: .25em;
      }
    }
  }
}

.owl-dt-calendar-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  .owl-dt-calendar-header {
    color: $color_3;

    .owl-dt-weekdays {
      th {
        font-size: .7em;
        font-weight: 400;
        text-align: center;
        padding-bottom: 1em;
      }
    }

    .owl-dt-calendar-table-divider {
      position: relative;
      height: 1px;
      padding-bottom: .5em;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -.5em;
        right: -.5em;
        height: 1px;
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }

  .owl-dt-calendar-cell {
    position: relative;
    height: 0;
    line-height: 0;
    text-align: center;
    outline: 0;
    color: $color_4;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .owl-dt-calendar-cell-content {
    position: absolute;
    top: 5%;
    left: 5%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    font-size: .8em;
    line-height: 1;
    border: 1px solid transparent;
    border-radius: 999px;
    color: $color_5;
    cursor: pointer;
  }

  .owl-dt-calendar-cell-out {
    opacity: .2;
  }

  .owl-dt-calendar-cell-today {
    &:not(.owl-dt-calendar-cell-selected) {
      border-color: $border-color_1;
    }
  }

  .owl-dt-calendar-cell-selected {
    color: $color_6;
    background-color: $background-color_2;
  }

  .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.85);
  }

  .owl-dt-calendar-cell-disabled {
    cursor: default;

    >.owl-dt-calendar-cell-content {
      &:not(.owl-dt-calendar-cell-selected) {
        color: $color_3;
      }
    }

    >.owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected {
      opacity: .4;
    }

    >.owl-dt-calendar-cell-today {
      &:not(.owl-dt-calendar-cell-selected) {
        border-color: $border-color_2;
      }
    }
  }

  .owl-dt-calendar-cell-active {
    &:focus {
      >.owl-dt-calendar-cell-content {
        &:not(.owl-dt-calendar-cell-selected) {
          background-color: $background-color_3;
        }
      }
    }
  }

  &:not(.owl-dt-calendar-cell-disabled) {
    &:hover {
      >.owl-dt-calendar-cell-content {
        &:not(.owl-dt-calendar-cell-selected) {
          background-color: $background-color_3;
        }
      }
    }
  }

  .owl-dt-calendar-cell-in-range {
    background: rgba(63, 81, 181, 0.2);
  }

  .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-from {
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
  }

  .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-to {
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
  }
}

.owl-dt-timer {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 7em;
  padding: .5em;
  outline: none;
}

.owl-dt-timer-box {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  width: 25%;
  height: 100%;
}

.owl-dt-timer-content {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  margin: .2em 0;

  .owl-dt-timer-input {
    display: block;
    width: 2em;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    outline: medium none;
    font-size: 1.2em;
    padding: .2em;
  }
}

.owl-dt-timer-divider {
  display: inline-block;
  align-self: flex-end;
  position: absolute;
  width: .6em;
  height: 100%;
  left: -.3em;

  &:before {
    content: '';
    display: inline-block;
    width: .35em;
    height: .35em;
    position: absolute;
    left: 50%;
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: $background-color_4;
    top: 35%;
  }

  &:after {
    content: '';
    display: inline-block;
    width: .35em;
    height: .35em;
    position: absolute;
    left: 50%;
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: $background-color_4;
    bottom: 35%;
  }
}

.owl-dt-control-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  background-color: $background-color_5;
  font-size: 1em;
  color: $color_5;

  .owl-dt-control-button-content {
    position: relative;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    outline: none;
  }
}

.owl-dt-control-period-button {
  .owl-dt-control-button-content {
    height: 1.5em;
    padding: 0 .5em;
    border-radius: 3px;
    -webkit-transition: background-color 100ms linear;
    transition: background-color 100ms linear;
  }

  &:hover {
    >.owl-dt-control-button-content {
      background-color: $background-color_1;
    }
  }

  .owl-dt-control-button-arrow {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 1em;
    height: 1em;
    margin: .1em;
    -webkit-transition: -webkit-transform 200ms ease;
    transition: -webkit-transform 200ms ease;
    transition: transform 200ms ease;
    transition: transform 200ms ease, -webkit-transform 200ms ease;
  }
}

.owl-dt-control-arrow-button {
  .owl-dt-control-button-content {
    padding: 0;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
  }

  svg {
    width: 50%;
    height: 50%;
    fill: currentColor;
  }
}

.owl-dt-control-arrow-button[disabled] {
  color: $color_3;
  cursor: default;
}

.owl-dt-inline-container {
  position: relative;
  width: 18.5em;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

  .owl-dt-calendar {
    width: 100%;
    height: 20.25em;
  }

  .owl-dt-timer {
    width: 100%;
  }
}

.owl-dt-popup-container {
  position: relative;
  width: 18.5em;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

  .owl-dt-calendar {
    width: 100%;
    height: 20.25em;
  }

  .owl-dt-timer {
    width: 100%;
  }
}

.owl-dt-dialog-container {
  max-height: 95vh;
  margin: -1.5em;

  .owl-dt-calendar {
    min-width: 250px;
    min-height: 330px;
    max-width: 750px;
    max-height: 750px;
  }

  .owl-dt-timer {
    min-width: 250px;
    max-width: 750px;
  }
}

.owl-dt-container-buttons {
  display: -webkit-box;
  display: flex;
  width: 100%;
  height: 2em;
  color: $color_7;
}

.owl-dt-container-control-button {
  font-size: 1em;
  width: 50%;
  height: 100%;
  border-radius: 0;

  .owl-dt-control-button-content {
    height: 100%;
    width: 100%;
    -webkit-transition: background-color 100ms linear;
    transition: background-color 100ms linear;
  }

  &:hover {
    .owl-dt-control-button-content {
      background-color: $background-color_6;
    }
  }
}

.owl-dt-container-info {
  padding: 0 .5em;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .owl-dt-container-range {
    outline: none;

    .owl-dt-container-range-content {
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      padding: .5em 0;
      font-size: .8em;
    }

    &:last-child {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  .owl-dt-container-info-active {
    color: $color_7;
    font-weight: 700;
  }
}

.owl-dt-container-disabled {
  opacity: 0.35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: default !important;
}

.owl-dt-trigger-disabled {
  opacity: 0.35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: default !important;
}

.owl-dt-timer-hour12 {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  color: $color_7;

  .owl-dt-timer-hour12-box {
    border: 1px solid currentColor;
    border-radius: 2px;
    -webkit-transition: background 200ms ease;
    transition: background 200ms ease;

    .owl-dt-control-button-content {
      width: 100%;
      height: 100%;
      padding: .5em;
    }

    &:focus {
      .owl-dt-control-button-content {
        background: #3f51b5;
        color: $color_8;
      }
    }

    &:hover {
      .owl-dt-control-button-content {
        background: #3f51b5;
        color: $color_8;
      }
    }
  }
}

.owl-dt-calendar-only-current-month {
  .owl-dt-calendar-cell-out {
    visibility: hidden;
    cursor: default;
  }
}

.owl-dt-inline {
  display: inline-block;
}

.owl-dt-control {
  outline: none;
  cursor: pointer;

  .owl-dt-control-content {
    outline: none;
  }

  &:focus {
    >.owl-dt-control-content {
      background-color: $background-color_1;
    }
  }

  &:not(:-moz-focusring) {
    &:focus {
      >.owl-dt-control-content {
        box-shadow: none;
      }
    }
  }
}

.owl-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

@media all and (orientation: landscape) {
  .owl-dt-dialog-container {
    .owl-dt-calendar {
      width: 58vh;
      height: 62vh;
    }

    .owl-dt-timer {
      width: 58vh;
    }
  }
}

@media all and (orientation: portrait) {
  .owl-dt-dialog-container {
    .owl-dt-calendar {
      width: 80vw;
      height: 80vw;
    }

    .owl-dt-timer {
      width: 80vw;
    }
  }
}

.cdk-overlay-container {
  z-index: 1060 !important;
}