.modal {

  .modal-header {
    padding: 1.5rem;
    padding-bottom: 0;
    border-bottom: 0;
    position: relative;
    @include align-items(flex-start);

    h5 {
      font-weight: 600;
      font-size: 1.35rem;
    }

    .btn-close {
      position: relative;
      top: 8px;
      min-width: 0 !important;
    }
  }

  .modal-body {
    padding: 1.5rem 1.5rem 2rem 1.5rem;
  }

  .modal-footer {
    padding: 1rem 1.5rem;
    background-color: $body-background;
    border-color: darken($body-background, 5%);
  }

  .modal-dialog-scrollable {
    .modal-header {
      padding-bottom: 1.5rem;
    }

    .modal-body {
      padding-top: 0;
    }
  }
}

.fullscreen-spinner-modal {
  .modal-content {
    background-color: rgba(0, 0, 0, .3);
  }

  figure {
    width: 4rem;
    height: 4rem;
  }

  svg {
    fill: #fff;
  }
}

// Create content modal
.create-content-modal,
.create-content-modal .modal-dialog-scrollable {
  .modal-header {
    border-bottom: 1px solid $gray-200;
  }

  .modal-body {
    padding-top: 2rem;
  }
}

.create-content-modal {
  .card {
    .form-group {
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid $gray-200;

      &:last-child {
        margin-bottom: 1rem;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}