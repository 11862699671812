.sidebar {
  --cui-sidebar-nav-icon-width: 2.5rem;
  --cui-sidebar-nav-icon-height: .95rem;
  --cui-sidebar-width: 14rem;
  --cui-sidebar-occupy-start: 14rem;

  @media (min-width: map-get($grid-breakpoints, xxl)) {
    --cui-sidebar-width: 16rem;
    --cui-sidebar-occupy-start: 16rem;
  }

  .sidebar-brand {
    @include justify-content(flex-start);
    padding-left: 1rem;
  }

  .sidebar-nav {
    padding-bottom: 1rem;

    .nav-title {
      font-size: .65rem;
      @include opacity(.7);
    }

    .nav-link {
      white-space: normal;
      font-size: .85rem;
      @include align-items(flex-start);

      .nav-icon {
        padding-left: 5px;
        top: 2px;
        position: relative;
      }

      &.active {
        background-color: $body-background;
        color: $primary;

        .nav-icon {
          color: $primary;
        }
      }
    }

    >.nav-group.show {
      .nav-group-items {
        background: rgba(0, 0, 0, .18);

        >.nav-group.show {
          .nav-group-items {
            background: rgba(0, 0, 0, .2);
          }
        }
      }
    }
  }


  .nav-group-items {
    .nav-link {
      padding-left: 3.25rem !important;
    }

    .nav-group-items {
      .nav-link {
        padding-left: 4.25rem !important;
      }

    }
  }

  >.nav-group {
    >.nav-link {
      >.nav-icon {
        display: none;
      }
    }
  }

  .nav-group-toggle::after {
    position: relative;
    top: 4px;
    padding-left: 3px;
  }

  // .nav-group.show {
  //   >.nav-group-toggle {
  //     color: #fff;
  //   }
  // }

  .sidebar-toggler {
    @media (min-width: map-get($grid-breakpoints, lg)) {
      display: none !important;
    }
  }

  &.show {
    .sidebar-toggler {
      &.custom {
        display: block;
        position: absolute;
        background: transparent;
        width: calc(100vw - var(--cui-sidebar-width));
        top: 0;
        left: var(--cui-sidebar-width);
        height: 100vh;

        &::before {
          display: none;
        }
      }
    }
  }

}