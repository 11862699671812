.label {
  @include inline-flex();
  @include align-items(center);
  @include justify-content(center);
  @include border-radius(4px);
  background-color: darken($primary, 15%);
  color: #fff;
  font-size: .7rem;
  line-height: 1;
  padding: .3rem .45rem;
  margin: .15rem;
  position: relative;

  &.label-lg {
    padding: .35rem .45rem;
    font-size: .85rem;
  }

  &.label-draft {
    background-color: $navy-400;
  }

  &.label-tag {
    background-color: darken($primary, 15%);
  }

  &.label-usage {
    background-color: transparent;
    border: 1px solid $info;
    color: darken($info, 5%);
  }

  &.label-success {
    background-color: $success;
  }

  &.label-danger {
    background-color: $danger;
  }

  &.label-attention {
    background-color: $warning;
  }

  &.label-gray {
    background-color: $gray-50;
  }
}