// Variable overrides
$enable-deprecation-messages: false;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px
);

// Colors
$primary: #00754A;
$primary-dark: #025D65;
$primary-light: #3ECEA9;
$primary-bright: #45E6BD;
$primary-pale: #d4e9e2;
$danger: #FF6740;
//$warning: #FF6740;
$body-background: lighten(#f9f9f9, .75%);

$gray-50: lighten(#ebedef, 3%);

// Blue gray
$blue-gray-25 : lighten(#ECEFF1, 2.5%);
$blue-gray-50: #ECEFF1;
$blue-gray-75: darken(#ECEFF1, 2.5%);
$blue-gray-100: #CFD8DC;
$blue-gray-200: #B0BEC5;
$blue-gray-300: #90A4AE;
$blue-gray-400: #78909C;
$blue-gray-500: #607D8B;
$blue-gray-600: #546E7A;
$blue-gray-700: #455A64;
$blue-gray-800: #37474F;
$blue-gray-900: #263238;
$blue-gray : $blue-gray-900;

// Navy
$navy-100: #E6EAF4;
$navy-200: #CCD6E9;
$navy-300: #B3C1DD;
$navy-400: #99ACD2;
$navy-500: #8098C7;
$navy-600: #6683BC;
$navy-700: #4D6EB1;
$navy-800: #3359A5;
$navy-900: #1A459A;
$navy: #00308F;

// Typography
$font-family-sans-serif: "SoDo Sans", system-ui, -apple-system, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Sidebar
$sidebar-bg: $primary;
$mobile-breakpoint: lg;

// Form
$form-input-height: 40px;