.body {
  .page-contents.listing {
    .left-panel {
      border-bottom: 1px solid $gray-100;
      margin-bottom: 2rem;
      padding-bottom: .5rem;

      @media (min-width: map-get($container-max-widths, lg)) {
        //border-right: 1px solid $gray-100;
        border-bottom: 0;
        padding-right: 1rem;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .right-panel {
      @media (min-width: map-get($container-max-widths, lg)) {
        padding-left: 1rem;
      }

      .card {

        .card-body {
          padding-top: 2rem;
        }

        .list-table {
          .table-list-search {
            .input-group {
              .input-group-text {
                border: 0;
                background-color: $gray-50;
              }
            }

            .form-control {
              border: 0;
              background-color: $gray-50;
              padding: .5rem 1rem;
              padding-left: 0;
            }
          }

          .table {
            font-size: .85rem;

            .sub-value,
            .timezone {
              line-height: 1.2;
              color: $gray-600;
            }

            tbody {
              .label {
                font-size: .6rem;
              }
            }
          }

          // thead th.status,
          // tbody td.status {
          //   position: sticky;
          //   right: 0;
          //   padding-left: 1rem;

          //   &::before {
          //     content: '';
          //     display: block;
          //     width: 100%;
          //     height: 100%;
          //     position: absolute;
          //     top: 0;
          //     left: 0;
          //     background-color: rgba(0, 0, 0, .04);
          //   }
          // }

          thead th.sticky,
          tbody td.sticky {
            position: sticky;
            left: 0;
            padding-right: .75rem;
            z-index: 1;

            &::before {
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, .04);
              z-index: -1;
            }
          }

          .select-mode-checkbox {
            width: 30px;

            &~th.sticky,
            &~td.sticky {
              left: 30px;
            }
          }

          tbody {
            tr.selected td {
              background-color: lighten($primary-pale, 3%);
            }
          }
        }
      }

      .pagination {
        .row {
          margin: 0;
        }

        .pages {
          @media (min-width: map-get($container-max-widths, xl)) {
            padding-left: 0;
          }
        }

        .show-per-page {
          @media (min-width: map-get($container-max-widths, xl)) {
            padding-right: 0;
          }
        }
      }

      .select-mode-wrapper {
        @include flex(0 0 100%);
        @include justify-content(center);
        border-top: 1px dashed $gray-100;
        margin-top: .5rem;
        padding-top: .5rem;

        @media (min-width: map-get($container-max-widths, md)) {
          @include flex(0 0 auto);
          margin-left: auto;
          border-top: 0;
          margin-top: 0;
          padding-top: 0;
        }

        .exit-select-mode {
          border-left: 1px solid $gray-100;
          padding-left: .35rem;
          margin-left: .5rem;

          .btn-exit-select-mode {
            &:hover {
              background-color: transparent;
              border-color: transparent;
              color: $gray-500;
            }
          }
        }
      }

      .select-mode-checkbox {
        input[type="checkbox"] {
          cursor: pointer;
        }
      }
    }


  }
}