@import "variables";
@import "mixins";

.chart {
  .charts-tools {
    padding: .7rem .75rem .85rem;
    //background-color: $blue-gray-25;
    position: relative;

    .row {
      width: 100%;
      position: relative;
      margin-left: 0;
      margin-right: 0;

      .tool-col {
        padding-left: .25rem;
        padding-right: .25rem;
        margin-bottom: .5rem;
      }
    }

    .filters {
      @include flex(1);

      .filter {
        @include flex(0 0 50%);
        padding-right: .5rem;
        margin-bottom: .25rem;
      }

      .filter-sm {
        @include flex(0 0 40%);
      }

      .filter-lg {
        @include flex(0 0 60%);
      }
    }

    .actions {
      padding-top: 22px;
    }

    .form-label {
      font-size: .65rem;
      height: 15px;
      margin-bottom: 0;
      font-weight: 500;
      color: $blue-gray-200;
    }

    .form-select {
      font-size: .8rem;
      padding: .25rem .5rem;
      min-height: 30px;
      border-color: $blue-gray-75;
      @include border-radius(4px);

      &:focus {
        box-shadow: none;
      }
    }

    input[type="text"] {
      font-size: .8rem;
      padding: .25rem .5rem;
      min-height: 30px;
      border-color: $blue-gray-75;
      @include border-radius(4px);
    }

    .btn-clear-input {
      width: 16px !important;
      height: 16px !important;
      top: 7px !important;
      font-size: .75rem !important;
    }

    .btn-export-chart {
      svg {
        width: 1.1rem;
        height: 1.1rem;
        font-size: 1.1rem;
      }
    }
  }

  .chart-container {
    padding: 1.2rem;

    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
}