// Font size
html {
  font-size: 15px;
}

// Colors
.bg-body {
  background-color: $body-background !important;
}

.bg-body-dark {
  background-color: darken($body-background, 2.75%) !important;
}

.gray-900 {
  color: $gray-900 !important;
}

.gray-800 {
  color: $gray-800 !important;
}

.gray-700 {
  color: $gray-700 !important;
}

.gray-600 {
  color: $gray-600 !important;
}

.gray-700 {
  color: $gray-700 !important;
}

.gray-600 {
  color: $gray-600 !important;
}

.gray-500 {
  color: $gray-500 !important;
}

.gray-400 {
  color: $gray-400 !important;
}

.gray-300 {
  color: $gray-300 !important;
}

.gray-200 {
  color: $gray-200 !important;
}

.gray-100 {
  color: $gray-100 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary;
  font-weight: 600;
}

img {
  width: 100%;
  max-width: 100%;
}

.text-dark {
  color: #000 !important;
}

.text-primary {
  color: $primary !important;
}

.text-danger {
  color: $danger !important;
}

.text-warning {
  color: $warning !important;
}

.text-success {
  color: $success !important;
}

// ######### Utilities #######
.flex-1 {
  @include flex(1 !important);
}

.flex-100 {
  @include flex(0 0 100% !important);
}

.flex-auto {
  @include flex(0 0 auto !important);
}

.flex-sm-auto {
  @media (min-width: map-get($grid-breakpoints, sm)) {
    @include flex(0 0 auto !important);
  }
}

.flex-md-auto {
  @media (min-width: map-get($grid-breakpoints, md)) {
    @include flex(0 0 auto !important);
  }
}

.flex-lg-auto {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    @include flex(0 0 auto !important);
  }
}

.flex-xl-auto {
  @media (min-width: map-get($grid-breakpoints, xl)) {
    @include flex(0 0 auto !important);
  }
}

// Icon img
.icon-img {
  @include inline-flex();
  @include align-items(center);
  @include justify-content(center);
  width: 1.5rem;
  height: 1.5rem;
  position: relative;

  svg,
  ::ng-deep svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.size-lg {
    width: 2rem;
    height: 2rem;
  }
}

// Width
.w-sm-auto {
  @media (min-width: map-get($grid-breakpoints, sm)) {
    width: auto !important;
  }
}

.w-md-auto {
  @media (min-width: map-get($grid-breakpoints, md)) {
    width: auto !important;
  }
}

.w-lg-auto {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    width: auto !important;
  }
}

.mw-lg {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    max-width: 960px !important;
  }
}

.mw-md-50 {
  @media (min-width: map-get($grid-breakpoints, md)) {
    max-width: 50% !important;
  }
}

.min-width-0 {
  min-width: 0 !important;
}

// Height
.h-form-input {
  min-height: $form-input-height !important;
}

// Text
.underline {
  text-decoration: underline !important;
}

.fs-small {
  font-size: .85rem !important;
}

.fs-smaller {
  font-size: .75rem !important;
}

small {
  font-size: .7rem !important;
}


// Margin & Padding
.mb-6 {
  margin-bottom: 4rem !important;
}

.pe-6 {
  padding-right: 4rem !important;
}

.pe-7 {
  padding-right: 5rem !important;
}

.pe-8 {
  padding-right: 6rem !important;
}

.px-lg-6 {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
}

.px-lg-7 {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
}

.px-lg-8 {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
}

.px-xl-8 {
  @media (min-width: map-get($grid-breakpoints, xl)) {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
}

// Ratio
.ratio-1-1 {
  aspect-ratio: 1 / 1 !important;
}

.ratio-16-9 {
  aspect-ratio: 16 / 9 !important;
}

// Shadow
.light-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.1) 1px 1px 3px -1px;
}

// Data list
.data-label {
  color: $gray-500;
  font-size: .8rem;
}

.data {
  font-weight: 600;
}


// Truncate 
.truncate-2-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

// Border 
.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

// Document figure preview
.document-preview {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  background-color: lighten($primary-pale, 5%) !important;
  color: $primary-light !important;

  .icon:not(.icon-c-s):not(.icon-custom-size),
  .icon {
    width: 2.55rem;
    height: 2.55rem;
  }
}

// Image preview
.image-preview {
  position: relative;

  &::before {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    content: '\ebb6';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 2.75rem;
    color: $gray-400;
    font-family: CoreUI-Icons-Linear !important;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    z-index: 0;
  }

  img {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: $gray-200;
  }
}