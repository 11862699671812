.toast-container {
  .ngx-toastr {
    background-image: none;
    padding: 0.75rem 1.25rem;
    width: auto;
    min-width: 200px;
    max-width: 300px;
  }

  .toast-success {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" x="0" y="0"  style="enable-background:new 0 0 512 512" viewBox="0 0 512 512"><path fill="%2300754A" d="M426.1 86.9c-93.2-93.2-244.4-93.2-337.6 0s-93.2 244.4 0 337.6 244.4 93.2 337.6 0 93.2-244.3 0-337.6zM257.2 462.5c-114 0-206.8-92.7-206.8-206.8S143.2 49 257.2 49 464 141.7 464 255.8s-92.7 206.7-206.8 206.7z"/><path fill="%2300754A" d="m221.3 305.8-73.4-73.4-22.7 22.6 96.1 96.1 167.5-167.5-22.7-22.7z"/></svg>');
    padding-left: 50px;
    background-position: 15px center;
    background-color: $primary-pale;

    .toast-message {
      color: $primary;
    }
  }

  .toast-error {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="Layer_1" x="0" y="0" style="enable-background:new 0 0 512 512" version="1.1" viewBox="0 0 512 512"><path d="M256 16C123.5 16 16 123.5 16 256s107.5 240 240 240 240-107.5 240-240S388.5 16 256 16zm147.1 387.1c-81.1 80.8-212.3 80.6-293.1-.4s-80.6-212.3.4-293.1 212.3-80.6 293.1.4c59.5 59.7 76.9 149.4 44.2 227-10.5 24.7-25.6 47.1-44.6 66.1z" fill="%23ff6740"/><path d="M240 128h32v192h-32zM240 352h32v32h-32z" fill="%23ff6740"/></svg>');
    padding-left: 50px;
    background-position: 15px center;

    .toast-message {
      color: $danger;
    }
  }
}