.card {

  &.bg-gray,
  .bg-gray {
    background-color: $gray-100;
    background-image: linear-gradient($gray-100, darken($gray-100, 2%));
  }

  &.bg-gray-light,
  .bg-gray-light {
    background-color: $gray-50;
  }

  .card-header.bg-gray {
    border-bottom-color: $gray-200;
  }

  &.card-warning {
    color: darken($warning, 5%);
    border-color: $warning;
    background-color: lighten($warning, 40%);
  }

  &.card-danger {
    color: darken($danger, 5%);
    border-color: $danger;
    background-color: lighten($danger, 35%);
  }

  &.card-draft {
    color: $navy-900;
    background-color: $navy-100;
    border-color: $navy-900;
  }
}