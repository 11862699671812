.body {
  .manage-content {
    .page-header {
      h1 {
        position: relative;

        .label {
          position: relative;
          top: -5px;
        }
      }
    }

    .page-contents {
      position: relative;

      .left-panel {
        margin-bottom: 2rem;

        @media (min-width: map-get($grid-breakpoints, xl)) {
          margin-bottom: 0;
        }
      }

      // .right-panel {
      //   @media (min-width: map-get($grid-breakpoints, xl)) {
      //     position: sticky;
      //     top: 115px;
      //   }
      // }

      .left-panel-col {
        @media (min-width: map-get($grid-breakpoints, xxl)) {
          @include flex(0 0 71%);
        }

        @media (min-width: map-get($grid-breakpoints, xxxl)) {
          @include flex(0 0 75%);
        }
      }

      .right-panel-col {
        @media (min-width: map-get($grid-breakpoints, xxl)) {
          @include flex(0 0 29%);
        }

        @media (min-width: map-get($grid-breakpoints, xxxl)) {
          @include flex(0 0 25%);
        }
      }

      .content-side-panel {
        .card-footer {
          background-color: $primary;
        }

        .side-panel-action-list {
          margin: 0 auto;
          max-width: 500px;
          @include flexbox();
          @include flex-wrap(wrap);
          @include justify-content(center);

          .action-item {
            padding: .25rem 0;
            @include flex(0 0 100%);

            @media (min-width: map-get($grid-breakpoints, sm)) {
              @include flex(0 0 50%);
              padding: .25rem .25rem;
            }

            @media (min-width: map-get($grid-breakpoints, xl)) {
              @include flex(0 0 100%);
            }


            button {
              padding: 0.5rem 1.25rem;
              min-height: 40px;
              font-size: .95rem;
            }

            &.primary {
              @media (min-width: map-get($grid-breakpoints, sm)) {
                @include flex(0 0 100%);
              }

              button {
                padding: 0.75rem 1rem;
                font-size: 1.1rem;
              }
            }

            .connected-btn {
              .btn:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }

              .btn:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                margin-left: -1px;
                width: 44px;
              }
            }
          }
        }
      }

      .text-editor-preview {
        max-height: 400px;
        min-height: 200px;
        overflow-y: auto;
      }
    }

    .form-group {
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid $gray-200;

      &:last-child {
        margin-bottom: 1rem;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    .uploaded-file-list {
      .card-body {
        @include align-items(center);
      }

      .uploaded-file-list-item {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .content-wrapper {
        @include flex(0 0 100%);
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items(center);

        @media (min-width: map-get($grid-breakpoints, md)) {
          @include flex(1);
          padding-right: 1.25rem;
        }
      }

      .action {
        @include flex(0 0 100%);
        margin-top: 1rem;

        @media (min-width: map-get($grid-breakpoints, md)) {
          @include flex(0 0 auto);
          margin-top: 0;
        }
      }

      figure {
        width: 70px;
        aspect-ratio: 1 / 1;
        margin-bottom: 0;
        overflow: hidden;
        @include border-radius(3px);

        &.image-preview {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &.document-preview {
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          @include border-radius(3px);
          background-color: darken($gray-100, 2.5%) !important;
          color: $gray-500 !important;

          .icon:not(.icon-c-s):not(.icon-custom-size),
          .icon {
            width: 2rem;
            height: 2rem;
          }
        }
      }

      .name {
        font-size: .9rem;
        // trubcate to 2 lines
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .info {
        padding-left: 1rem;
        @include flex(1);
      }
    }
  }
}