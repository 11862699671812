.pagination {
  .page-link {
    height: 36px;
    min-height: 36px;
  }

  .form-select {
    height: 36px;
    min-height: 36px;
  }
}