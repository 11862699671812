@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-BlackItalic.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-Regular.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-Bold.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-Italic.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-Black.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-Light.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SoDo Sans';
  src: url('/assets/fonts/SoDoSans/SoDoSans-SemiBoldItalic.woff2') format('woff2'),
    url('/assets/fonts/SoDoSans/SoDoSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

// Kalam
@font-face {
  font-family: 'Kalam';
  src: url('/assets/fonts/kalam/Kalam-Regular.woff2') format('woff2'),
    url('/assets/fonts/kalam/Kalam-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kalam';
  src: url('/assets/fonts/kalam/Kalam-Bold.woff2') format('woff2'),
    url('/assets/fonts/kalam/Kalam-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}