.invalid-message {
  color: $danger;
  font-size: .85rem;
}

.form-label {
  font-size: .9rem;
  font-weight: 600;
  color: $primary;
  padding-right: .25rem;
}

.form-check-label {
  cursor: pointer;
  margin-bottom: .25rem;
}

.form-sublabel {
  color: $gray-500;
  font-size: .8rem;
  font-style: italic;
  font-weight: 500;
  display: block;
  margin-top: .1rem;
  margin-bottom: .25rem;
  line-height: 1.3;
}

.form-control {
  font-weight: 600;
  min-height: $form-input-height;

  &:focus {
    box-shadow: none;
  }

  &::-webkit-input-placeholder {
    color: $gray-300;
    font-weight: 300;
  }

  &:-moz-placeholder {
    color: $gray-300;
    font-weight: 300;
  }

  &::-moz-placeholder {
    color: $gray-300;
    font-weight: 300;
  }

  &:-ms-input-placeholder {
    color: $gray-300;
    font-weight: 300;
  }

  &:disabled,
  &[disabled="disabled"],
  &.owl-dt-trigger-disabled {
    background-color: $gray-100;
    border-color: $gray-200;
    color: $gray-700;
    pointer-events: none;
    @include opacity(.75);
  }
}

.form-select {
  min-height: $form-input-height;

  &:disabled,
  &[disabled="disabled"] {
    background-color: $gray-100;
    border-color: $gray-200;
    color: $gray-700;
  }
}

.form-error {
  color: $danger;
  font-size: .8rem;
  //font-style: italic;
}

html:not([dir=rtl]) .form-check-inline,
.form-check-inline {
  width: 50%;
  margin-right: 0;
  padding-right: 1.25rem;

  @media (min-width: map-get($grid-breakpoints, md)) {
    width: 33.3333%;
  }

  @media (min-width: map-get($grid-breakpoints, xxxl)) {
    width: 25%;
  }
}

// Select menu with search
.ngx-dropdown-container {
  //overflow-x: hidden;

  .ngx-dropdown-button {
    min-height: 40px !important;

    .nsdicon-angle-down {
      &::before {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23323a49' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
        border-width: 0 !important;
        transform: none !important;
        width: 15px !important;
        height: 15px !important;
        top: 3px !important;
      }
    }
  }

  .ngx-dropdown-list-container {
    padding: 0 !important;
    overflow-x: hidden;

    .selected-items {
      padding: 0 .75rem !important;

      .selected-item {
        background-color: $primary-light !important;
        overflow: hidden;
        @include border-radius(3px);

        .nsdicon-close {
          margin-right: .5rem;
          color: $danger;
          font-weight: 400;
        }
      }
    }

    .available-items {
      padding-left: .75rem !important;

      .available-item {
        padding: .5rem 1rem !important;
        margin-left: -0.75rem !important;

        &:hover {
          background-color: $gray-50;
        }
      }
    }
  }

  .search-container {
    background-color: $gray-50;
    margin-top: 0 !important;
    position: relative;
    padding: .5rem !important;
    border-bottom: 1px solid $gray-100;

    >input {
      padding: .55rem .25rem 0 .25rem !important;
      border-bottom: 0 !important;
      font-size: .85rem !important;
    }

    >label {
      top: .25rem !important;
      left: .75rem !important;

      &.active {
        transform: translateY(1px) scale(.8) !important;
      }
    }
  }
}

// File input
input[type=file]::file-selector-button {
  display: none;
}

// Summernote
.note-editor {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}

// Password related
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

input[type=password]::-webkit-contacts-auto-fill-button,
input[type=password]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}