.header {
  padding-bottom: .25rem;

  .breadcrumb {
    font-size: .85rem;
  }

  .header-divider {
    margin-bottom: .25rem;
  }

  .header-nav {
    button.btn-user-avatar {
      border: 0 !important;
      min-width: auto;
    }
  }

  .user-dropdown {
    width: 250px;

    .account-info {
      .info {
        font-size: .95rem;
        line-height: 1.25;


        >div {
          word-break: break-word;
        }

        .user-name {
          font-size: 1rem;
          font-weight: 600;
        }

        .user-email {
          color: $gray-700;
          font-style: italic;
          font-size: .9rem;
        }

        .user-role {
          .label {
            margin: 0;
            font-size: .75rem;
            text-transform: uppercase;
            background-color: $primary;
            padding: .3rem .5rem .25rem;
          }
        }
      }
    }
  }
}