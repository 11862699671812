.dropdown-menu {
  .dropdown-item {
    cursor: pointer;
    color: $primary;

    &:hover {
      background-color: $primary-pale;
    }
  }

}